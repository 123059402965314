/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { Img } from "../../../sharedComponents/Images";
import { Heading } from "../../Heading";
import { Cell, Row } from "../../Grid";

import * as styles from "./Banner.module.scss";

const Banner = ({ title, text, image, logo }) => (
	<Row cols={2}>
		{(title || text) && (
			<Cell>
				<div>
					{title && <Heading as="h1">{title}</Heading>}
					{text && <div className={styles.bannerText} dangerouslySetInnerHTML={{ __html: text }} />}
					{logo && <Img image={logo} alt={title} />}
				</div>
			</Cell>
		)}
		{image && (
			<Cell>
				<div className={styles.imageContainer}>
					<div className={styles.imagesWrapper}>
						<Img image={image} alt={title && title} />
					</div>
				</div>
			</Cell>
		)}
	</Row>
);

Banner.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	logo: PropTypes.arrayOf(PropTypes.object),
};

Banner.defaultProps = {
	title: null,
	text: null,
	image: null,
	logo: null,
};

export default Banner;
export { Banner };
