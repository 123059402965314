import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { useAlerts } from "../context";
import { CloudinaryImageAdapter } from "../components/CloudinaryImageAdapter";
import { BaseLayout } from "../components/Layouts/BaseLayout";
import { FluidSections } from "../components/FluidSections";
import { Section } from "../components/Section";
import { Banner } from "../components/HotelBrandPage/Banner/Banner";

const HotelBrand = ({ data, pageContext, location }) => {
	/* Retrieve Page Data from the page query */
	const pageData = data.contentfulHotelChain;
	// Get banner data

	const bannerLogo = pageData?.logo?.[0];
	const bannerText = pageData?.hotelDescription?.childMarkdownRemark?.html;
	const bannerImage = pageData?.hotelImage?.[0];
	let pageHeader = pageData.headerNavigation;

	const altLangPages = data.allSitePage;
	const { homepagePaths, defaultHeader, travelAlertsUrl, defaultFooter } = data;

	const { alertsCount } = useAlerts();

	let pageFooter = pageData.footer;

	if (!pageHeader) {
		pageHeader = defaultHeader;
	}

	if (!pageFooter) {
		pageFooter = defaultFooter;
	}
	/* Use the FluidSection component to build out the fluid page sections */
	return (
		<BaseLayout
			location={location}
			pageContext={pageContext}
			seo={pageData.seo}
			altLangPages={altLangPages}
			footerData={pageFooter}
			headerNavigation={pageHeader}
			homepagePaths={homepagePaths}
			alerts={alertsCount}
			travelAlertsUrl={travelAlertsUrl?.path}
			pageType={pageContext?.pageType}
		>
			<Section>
				<Banner
					title={pageData?.name}
					text={bannerText}
					image={bannerImage && CloudinaryImageAdapter({ cloudinaryImage: bannerImage })}
					logo={
						bannerLogo && CloudinaryImageAdapter({ cloudinaryImage: bannerLogo, maxWidth: 1200 })
					}
				/>
			</Section>
			{pageData?.sections && (
				<FluidSections data={pageData?.sections} location={location} locale={pageContext.locale} />
			)}
		</BaseLayout>
	);
};

/* Declare page prop */
HotelBrand.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default HotelBrand;

/* Query page data */
export const pageQuery = graphql`
	query GetHotelChainPageData($id: String!, $locale: String!, $ctf_id: String!) {
		contentfulHotelChain(id: { eq: $id }) {
			title
			name
			sections {
				__typename
				...Collection
				...ComponentBlock
				...HardCodedComponent
				...LinkWithModal
				...ListLink
				...MultiImageBlock
				...Paragraph
				...PromotionRule
				...Section
				...SplitContentWrapper
				...TabsAndAccordion
				...TimedComponent
				...VideoPlayer
			}
			seo {
				...SEO
			}
			logo {
				secure_url
				width
				height
				raw_transformation
				version
				public_id
				format
			}
			hotelDescription {
				childMarkdownRemark {
					html
				}
			}
			hotelImage {
				secure_url
				width
				height
				raw_transformation
				version
				public_id
				format
			}
		}
		homepagePaths: allSitePage(filter: { context: { template: { eq: "homepage" } } }) {
			edges {
				...homepagePaths
			}
		}
		allSitePage(filter: { context: { ctf_id: { eq: $ctf_id } } }) {
			edges {
				...LanguageToggle
			}
		}
		defaultHeader: contentfulHeaderNavigation(
			contentful_id: { eq: "4WLelYKXDOXBf7CGGtf52z" }
			node_locale: { eq: $locale }
		) {
			...headerNavigation
		}
		defaultFooter: contentfulFooter(
			contentful_id: { eq: "3TjYjWvnKS88bdse66t4oo" }
			node_locale: { eq: $locale }
		) {
			...Footer
		}
		travelAlertsUrl: sitePage(context: { template: { eq: "alerts" }, locale: { eq: $locale } }) {
			path
		}
	}
`;
